var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-container",style:({
    transform: `translate(${_vm.local_item.left}px, ${_vm.local_item.top}px)`,
    width: `${_vm.calculatedWidth}px`,
    height: `${_vm.calculatedHeight}px`,
    position: 'absolute',
    cursor: _vm.isDragging ? 'grabbing' : 'grab'
  }),on:{"mousedown":_vm.start_drag}},[_c('div',{staticClass:"label-overlay"},[_vm._v(" "+_vm._s(JSON.stringify(_vm.local_item, null, 2))+" ")]),_c('img',{staticClass:"image-content",attrs:{"src":_vm.image_path ? _vm.image_path : require('@/assets/images/placeholder.png')}})])
}
var staticRenderFns = []

export { render, staticRenderFns }