<template>
  <div 
    class="image-container"
    :style="{
      transform: `translate(${local_item.left}px, ${local_item.top}px)`,
      width: `${calculatedWidth}px`,
      height: `${calculatedHeight}px`,
      position: 'absolute',
      cursor: isDragging ? 'grabbing' : 'grab'
    }"
    @mousedown="start_drag"
  >
    <!-- Label displaying local_item data -->
    <div class="label-overlay">
      {{ JSON.stringify(local_item, null, 2) }}
    </div>

    <img
      :src="image_path ? image_path : require('@/assets/images/placeholder.png')"
      class="image-content"
    />
  </div>
</template>

<script>
import { get_file } from '@/core/services/fileDownload';

export default {
  name: 'PDFDraggableImage',
  props: {
    image: Object,
    imageIndex: Number,
    paths: Object,
    scale: Number, // Scale factor from parent
  },

  data() {
    return {
      isDragging: false,
      dragOffsetX: 0,
      dragOffsetY: 0,
      image_path: null,
      originalWidth: null, // Store original size
      originalHeight: null,
      local_item: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        scale: 1
      }
    };
  },

  computed: {
    calculatedWidth() {
      return this.originalWidth ? this.originalWidth * this.scale : this.local_item.width;
    },
    calculatedHeight() {
      return this.originalHeight ? this.originalHeight * this.scale : this.local_item.height;
    }
  },

  watch: {
    image: {
      deep: true,
      async handler(val) {
        if (!val) return;

        // Ensure reactive object update
        this.local_item = { ...val };

        await this.get_image_url(this.local_item.file_id);
      },
      immediate: true
    },

    scale(newScale) {
      if (!this.originalWidth || !this.originalHeight || this.isDragging) return;

      this.local_item.width = this.originalWidth * newScale;
      this.local_item.height = this.originalHeight * newScale;
    }
  },

  methods: {
    async get_image_url(file_id) {
      if (!file_id) return;
      
      const res = await get_file(file_id);
      this.image_path = res.public_path;

      // Get image dimensions from API response only if not dragging
      if (res.meta && res.meta.size && !this.isDragging) {
        this.originalWidth = res.meta.size.width;
        this.originalHeight = res.meta.size.height;
        this.local_item.width = this.originalWidth * this.scale;
        this.local_item.height = this.originalHeight * this.scale;
      }
    },

    start_drag(event) {
      if (!this.originalWidth || !this.originalHeight) return;
      if (event.button !== 0) return; // Only allow left mouse button
      this.isDragging = true;

      const rect = this.$el.getBoundingClientRect();
      this.dragOffsetX = event.clientX - rect.left;
      this.dragOffsetY = event.clientY - rect.top;

      document.addEventListener('mousemove', this.drag_move);
      document.addEventListener('mouseup', this.end_drag);
    },

    drag_move(event) {
      if (!this.isDragging) return;

      const rect = this.$el.parentElement.getBoundingClientRect();

      // Update position with reactivity
      this.local_item.left = event.clientX - rect.left - this.dragOffsetX;
      this.local_item.top = event.clientY - rect.top - this.dragOffsetY;

      this.$emit('update-position', this.imageIndex, this.local_item.left, this.local_item.top);
    },

    end_drag() {
      if (!this.isDragging) return;
      
      this.isDragging = false;

      document.removeEventListener('mousemove', this.drag_move);
      document.removeEventListener('mouseup', this.end_drag);
    }
  }
};
</script>

<style scoped>
.image-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px dashed #aaa;
  background: rgba(0, 0, 0, 0.05);
}

/* Label styling */
.label-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 10px;
  padding: 4px;
  border-radius: 3px;
  max-width: 150px;
  white-space: pre-wrap;
  overflow: hidden;
}

.image-content {
  width: 100%;
  height: auto;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}
</style>
