<template>
  <b-modal size="xl" ref="form-edit-modal" hide-footer>
    <template #modal-title>
      {{ local_form.id ? $t('PDF_TEMPLATE.EDIT') : $t('PDF_TEMPLATE.CREATE') }}
    </template>
    
    <div class="container">
      <form v-if="local_form">
        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input
              id="name"
              name="name"
              :title="$t('PDF_TEMPLATE.NAME')"
              v-model="local_form.name"
              layout="vertical"
              validation_type="TEXT"
              :validate="force_validate"
              :placeholder="$t('PDF_TEMPLATE.NAME')"
              :required="true"
              :invalid_text="$t('PDF_TEMPLATE.NAME_REQUIRED')"
              @validated="validated"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-select-input2
              id="type"
              name="type"
              :title="$t('PDF_TEMPLATE.TYPE')"
              layout="vertical"
              validation_type="TEXT"
              v-model="local_form.type"
              :items="type_options"
              :required="false"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input
              id="header_str"
              name="header_str"
              v-model="local_form.header_str"
              layout="vertical"
              validation_type="TEXT"
              :validate="force_validate"
              :title="$t('PDF_TEMPLATE.HEADER')"
              :placeholder="$t('PDF_TEMPLATE.HEADER')"
              :required="false"
              @validated="validated"
            />
            <color-picker-input id="header_color" v-model="local_form.header_color" />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input
              id="subheader_str"
              name="subheader_str"
              v-model="local_form.subheader_str"
              layout="vertical"
              validation_type="TEXT"
              :title="$t('PDF_TEMPLATE.SUBHEADER')"
              :validate="force_validate"
              :placeholder="$t('PDF_TEMPLATE.SUBHEADER')"
              :required="false"
              @validated="validated"
            />
            <color-picker-input id="subheader_color" v-model="local_form.subheader_color" />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-textarea
              id="descr_str"
              name="descr_str"
              v-model="local_form.descr_str"
              layout="vertical"
              :validate="force_validate"
              :title="$t('PDF_TEMPLATE.DESCRIPTION')"
              :placeholder="$t('PDF_TEMPLATE.DESCRIPTION')"
              :required="false"
              @validated="validated"
            />
            <color-picker-input id="descr_color" v-model="local_form.descr_color" />
          </div>
        </div>

        <!-- PDF Designer Component -->
        <div class="ml-row mt-4">
          <div class="ml-col">
            <h4 class="mt-8">{{ $t('PDF_TEMPLATE.DESIGNER') }}</h4>
            <PDFDesigner v-if="local_form" :item="local_form" @update:item="update_pdf_template_data" />
          </div>
        </div>

        <ErrorPopup :error="error_popup_message" />

        <div class="ml-row mt-8">
          <div class="ml-col">
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="spinning"
              @clicked="on_save_clicked"
              @close="hide"
              ref="saveButton"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput.vue';
import PDFDesigner from '@/view/pages/ml/pdf_template/PDFDesigner.vue';

export default {
  name: 'PDFTemplateEditorModal',
  mixins: [toasts],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    RightModalSaveAndCloseButtons,
    ErrorPopup,
    ColorPickerInput,
    PDFDesigner
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'customer']),
    type_options() {
      return [
        { text: this.$t('PDF_TEMPLATE.TYPES.MEMBERSHIP_CERTIFICATE'), value: 'MEMBERSHIP_CERTIFICATE' }
      ];
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.local_form = { ...val };
      },
      immediate: true,
    },
  },
  data() {
    return {
      local_form: {},
      force_validate: 0,
      is_form_valid: false,
      spinning: false,
      error_popup_message: null,
    };
  },
  methods: {
    update_pdf_template_data(updatedItem) {
      this.local_form = { ...updatedItem };
    },

    validated(field_name, value, valid) {
      this.local_form[field_name] = value;
      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_save_clicked() {
      this.spinning = true;
      this.force_validate += 1;
      this.is_form_valid = true;

      this.$nextTick(async () => {
        if (this.is_form_valid) {
          this.spinning = false;
          if (this.local_form.id) {
            await this.update_pdf_template();
          } else {
            await this.create_pdf_template();
          }
        }
        setTimeout(() => {
          this.spinning = false;
          this.$refs['saveButton'].stop();
        }, 500);
      });
    },

    async create_pdf_template() {
      try {
        this.local_form.customer_id = this.customer.id;
        this.local_form.company_id = this.currentCompanyId;
        this.local_form.type = 'MEMBERSHIP_CERTIFICATE';

        const response = await axios.post('/pdf_template', this.local_form);
        if (response.status === 201) {
          this.local_form = { ...response.data };
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PDF_TEMPLATE.CREATED'));
          this.$emit('created', response.data);
        }
      } catch (err) {
        console.error('create_pdf_template error', err);
        this.toastr('danger', this.$t('COMMON.FAILURE'), this.$t('PDF_TEMPLATE.UNABLE_CREATE'));
      }
    },

    async update_pdf_template() {
      try {
        const response = await axios.put(`/pdf_template/${this.local_form.id}`, this.local_form);
        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PDF_TEMPLATE.UPDATED'));
          this.$emit('updated', this.local_form);
        }
      } catch (err) {
        console.error('update_pdf_template error', err);
        this.toastr('danger', this.$t('COMMON.FAILURE'), this.$t('PDF_TEMPLATE.UNABLE_UPDATE'));
      }
    },

    show() {
      this.$refs['form-edit-modal'].show();
    },

    hide() {
      this.$refs['form-edit-modal'].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.ml-row {
  margin-bottom: 12px;
}
</style>
