<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 py-5 ml-2">
      <h3 class="card-title align-items-start flex-column">

      </h3>
      <div class='d-flex justify-content-end'>
        <div class="card-header border-0 py-5">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                v-model="showAll"
                name=""
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer font-size-lg font-weight-bolder">{{ $t('TEMPLATE.SHOW_ALL') }}</span>
          </div>
        </div>
        <div class="card-toolbar">

          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createTemplateClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('TEMPLATE.NEW') }} </a
          >
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->


    <b-row class="mb-2">
      <b-col md="12" lg="3">

      </b-col>
      <b-col md="12" lg="3">

      </b-col>
      <b-col md="12" lg="3">

      </b-col>
      <b-col md="12" lg="3">
        <div class="d-sm-flex justify-content-end text-right float-right align-bottom w-100">
          <b-form-select class="pagination-select" v-model="perPage" :options="options"></b-form-select>
          <b-pagination
              class="mb-0 mr-2"
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="template-table"
              first-number
              last-number
            ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <div class="card-body pt-0 px-0">
      <b-table
        id="template-table"
        :fields="headers"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="light"
        class="mh-100 table-striped"
        sticky-header
        striped
        hover
      >
        <template #cell(is_email)="row">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                :checked="Boolean(row.item.is_email)"
                disabled
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>
        <template #cell(is_sms)="row">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                :checked="Boolean(row.item.is_sms)"
                disabled
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>
        <template #cell(is_system)="row">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                v-model='row.item.is_system'
                @change.prevent='changeSystem(row.item)'
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class='justify-content-end d-flex'>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="copyTemplateClicked(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <i class="far fa-copy" style="color:#3699FF"></i>
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="selectTemplateClicked(row.item.template_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="deleteTemplateClicked(row.item.template_id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>

      <!--end::Table-->
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding: 0.60rem 2rem 0.65rem 1rem;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import TemplateTableRow from "@/view/pages/ml/templates/TemplateTableRow.vue";
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: "templates-table",
  props: ["items", "titles", "currentCompanyId"],
  emits: ['createTemplateClicked', 'copyTemplateClicked', 'selectTemplateClicked', 'deleteTemplateClicked', 'changeSystem'],
  mixins: [ toasts ],
  components: {
    TemplateTableRow,
  },
  watch: {
    showAll() {

    },
    items() {

    },
  },
  computed:{
    ...mapGetters([]),
  },
  mounted() {

  },
  methods: {

    createTemplateClicked() {
      this.$emit('createTemplateClicked');
    },
    copyTemplateClicked(template) {
      const data = template;
      this.$emit('copyTemplateClicked', data, this.showAll);
    },
    selectTemplateClicked(template_id) {
      this.$emit('selectTemplateClicked', template_id);
    },
    deleteTemplateClicked(template_id) {
      this.$emit('deleteTemplateClicked', template_id);
    },
    changeSystem(template) {
      this.$emit('changeSystem', template);
    },
    getTitle(title_id) {
      this.titles.forEach(t => {

        if (t.id == title_id) {
          return t.name;
        }
      });

      return "";
    },
  },
  data() {
    return {
      showAll: false,
      currentPage: 1,
      perPage: 100,
      options: [
        100, 150, 200
      ],
      headers: [
        {
          key: 'name',
          label: 'Namn',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'created_at',
          label: 'Skapat',
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
        {
          key: 'is_email',
          label: 'E-post',
          thClass: 'w-80px align-middle',
          tdClass: 'align-middle',
        },
        {
          key: 'is_sms',
          label: 'SMS',
          thClass: 'w-50px align-middle',
          tdClass: 'align-middle',
        },
        {
          key: 'is_system',
          label: 'System',
          thClass: 'w-50px align-middle',
          tdClass: 'pl-8 align-middle'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-12 align-middle',
        },
      ],

    };
  }
};
</script>
