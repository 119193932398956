<template>
  <div class="pdf_designer">
    <!-- Canvas Settings -->
    <div class="ml-row">
      <div class="ml-col">
        <memlist-text-input
          id="width"
          name="width"
          :title="$t('PDF_TEMPLATE.WIDTH')"
          v-model.number="local_form.width"
          layout="vertical"
          validation_type="TEXT"
          :required="true"
          @input="emit_update"
        />
      </div>
      <div class="ml-col">
        <memlist-text-input
          id="height"
          name="height"
          :title="$t('PDF_TEMPLATE.HEIGHT')"
          v-model.number="local_form.height"
          layout="vertical"
          validation_type="TEXT"
          :required="true"
          @input="emit_update"
        />
      </div>
    </div>

    <!-- Upload Images -->
    <div class="ml-row mt-4">
      <div class="ml-col">
        <h4>{{ $t('PDF_TEMPLATE.UPLOAD_IMAGES') }}</h4>

        <div v-for="(image, index) in local_form.images" :key="index" class="image-item">
          <!-- File Upload -->
          <memlist-file-upload
            class="mt-4"
            :title="$t('PDF_TEMPLATE.IMAGE')"
            type="image"
            layout="vertical"
            v-model="image.file_id"
            @uploaded="(file) => update_image_file(index, file.file_id)"
            @deleted="() => remove_image(index)"
          />

          <!-- Scale Slider -->
          <memlist-slider-input
            class="mt-2"
            :title="$t('PDF_TEMPLATE.SCALE')"
            v-model="image.scale"
            :min="0.01"
            :max="2"
            :step="0.01"
            @input="emit_update"
          />
        </div>
        
        <button class="add_image_btn mt-4" @click.prevent="add_new_image">
          + {{ $t('PDF_TEMPLATE.ADD_IMAGE') }}
        </button>
      </div>
    </div>

    <!-- Drag & Drop Canvas -->
    <div class="editor" :style="{ width: `${local_form.width}px`, height: `${local_form.height}px` }">
      <PDFDraggableImage
        v-for="(image, index) in local_form.images"
        :key="index"
        :image="image"
        :imageIndex="index"
        :scale="image.scale"
        @update-position="update_position"
      />
    </div>
  </div>
</template>

<script>
import { get_file } from '@/core/services/fileDownload';
import PDFDraggableImage from '@/view/pages/ml/pdf_template/PDFDraggableImage.vue';

export default {
  name: 'PDFDesigner',
  components: { 
    PDFDraggableImage 
  },
  props: ['item'],

  data() {
    return {
      local_form: {},
    };
  },

  watch: {
    item: {
      deep: true,
      async handler(val) {
        this.local_form = { ...val };

        if (!this.local_form.images) {
          this.local_form.images = [];
        }

        if (!this.local_form.width) {
          this.local_form.width = 595;
        }

        if (!this.local_form.height) {
          this.local_form.height = 420;
        }

      },
      immediate: true
    }
  },

  methods: {
    
    emit_update() {
      this.$emit('update:item', { ...this.local_form });
    },

    async update_image_file(index, file_id) {
      this.local_form.images[index].file_id = file_id;
      this.emit_update();
    },

    update_position(index, left, top) {
      this.local_form.images[index].left = left;
      this.local_form.images[index].top = top;
      this.emit_update();
    },

    remove_image(index) {
      this.local_form.images.splice(index, 1);
      this.emit_update();
    },

    add_new_image() {
      if (!Array.isArray(this.local_form.images)) {
        this.$set(this.local_form, "images", []);
      }

      this.$set(this.local_form.images, this.local_form.images.length, {
        file_id: null,
        left: 100,
        top: 100,
        scale: 1,
        width: 100,
        height: 100
      });

      this.emit_update();
    }
  }
};
</script>

<style scoped>
.pdf_designer {
  padding: 16px;
  border: 1px solid #ccc;
  background: #f9f9f9;
}

.editor {
  position: relative;
  background: white;
  border: 1px solid #ccc;
  overflow: hidden;
  margin-top: 16px;
}

.add_image_btn {
  padding: 8px 12px;
  border: none;
  background: #3699FF;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.add_image_btn:hover {
  background: #0056b3;
}
</style>
