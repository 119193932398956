<template>
  <div>
    <!-- Top Controls -->
    <div class="px-4">
      <b-row>
        <b-col>
          <!-- Create PDF Template Button -->
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2"
            style="float: right;"
            @click.prevent="create_pdf_template"
          >
            <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
            {{ $t('PDF_TEMPLATE.CREATE') }}
          </a>
        </b-col>
      </b-row>

    </div>

    <!-- Pagination Controls -->
    <b-row class="mt-3">
      <b-col cols="4">
        <div class="bottom-alignment">
          <strong>{{ $t('COMMON.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>
      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[10, 50, 100]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <!-- PDF Template Table -->
    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="pdf-template-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(actions)="data">
        <memlist-table-button class="mr-3" @click="edit_template(data.item)" svg="/assets/svg/Write.svg" :tooltip="$t('COMMON.EDIT')" />
        <memlist-table-button class="mr-3" @click="delete_template(data.item.id)" svg="/assets/svg/Trash.svg" :tooltip="$t('COMMON.DELETE')" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
  name: 'PDFTemplateTable',
  mixins: [toasts],
  emits: ['on_select_item', 'on_delete_item', 'create_clicked'],
  computed: {
    ...mapGetters(['currentCompanyId']),

    headers() {
      return [
        {
          label: this.$t('PDF_TEMPLATE.ID'),
          sortable: true,
          key: 'id',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('PDF_TEMPLATE.NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('PDF_TEMPLATE.CREATED_AT'),
          sortable: true,
          key: 'created_at',
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD')
          }
        },
        {
          label: this.$t('COMMON.ACTIONS'),
          key: 'actions',
          sortable: false,
        }
      ];
    }
  },
  mounted() {
    this.filters = {
      text: '',
      created_from: null,
      created_to: null
    };
  },
  data() {
    return {
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 10,
      search: null
    };
  },
  methods: {
    create_pdf_template() {
      this.$emit('create_clicked');
    },
    edit_template(item) {
      this.$emit('on_select_item', item);
    },
    delete_template(id) {
      this.$emit('on_delete_item', id);
    },
    
    get_pagination(callback) {
      axios
        .get(`/pdf_template`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);

            return;
          }
          if (callback) {
            this.total_rows = res.data.length;
            callback(res.data || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },
    
    ajax_pagination(ctx, callback) {
      
      this.get_pagination(callback);
      return null;
    },
    refresh() {
      this.$refs['pdf-template-table'].refresh();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_common_modals.scss";

.status-badge {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 1pc;
  border: 1px solid;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}
</style>
