<template>
  <div>
    <b-card
      title=""
      class="mb-2 edit-template-container"
      ref="edit-template"
      id="edit-template"
      hide-footer
    >
      <div>
        <b-form class="mt-8" @submit.prevent="saveTemplate">
          <b-form-group id="input-group-template_id" label="ID" label-for="input-template_id">
            <b-form-input
              id="input-template_id"
              v-model="local_value.template_id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-1" :label="$t('TEMPLATE.NAME')" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="local_value.name"
              type="text"
              required
              :placeholder="$t('TEMPLATE.NAME')"
              :state="valid_name_state"
            ></b-form-input>
          </b-form-group>

          <memlist-select-input2
            v-if="isTHS"
            id="sendout_template_type"
            name="sendout_template_type"
            :title="$t('SALES.SENDOUT_TEMPLATE')"
            layout="vertical"
            validation_type="TEXT"
            v-model="sendout_template_type"
            :items="sendout_template_type_options"
            :required="false"
            :invalid_text="''"
            :info_text="''"
          />

          <EmailWithValidDomain
            v-if="local_value.is_email"
            ref="reply_to"
            title="Reply-to"
            :isDisabled="local_value.is_sms"
            :isRequired="local_value.is_email"
            v-bind:value="local_value.reply_to" v-on:update:email="replyToUpdated"
          />

          <EmailWithValidDomain
            v-if="local_value.is_email"
            ref="from_email"
            :title="$t('TEMPLATE.FROM_EMAIL')"
            :isDisabled="local_value.is_sms"
            :isRequired="local_value.is_email"
            v-bind:value="local_value.from_email" v-on:update:email="fromEmailUpdated"
          />

          <b-form-group
            v-if="local_value.is_email"
            id="from_name-group"
            :label="$t('PAGES.SENDOUT.FROM_NAME')"
            label-for="from_name">
            <b-form-input
              id="from_name"
              v-model="local_value.from_name"
              :disabled="local_value.is_sms"
              :placeholder="$t('PAGES.SENDOUT.FROM_NAME')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="local_value.is_email"
            id="subject-group"
            :label="$t('PAGES.SENDOUT.SUBJECT')"
            label-for="subject">
            <b-form-input
              id="subject"
              v-model="local_value.subject"
              :disabled="local_value.is_sms"
              :placeholder="$t('PAGES.SENDOUT.SUBJECT')"
            ></b-form-input>
          </b-form-group>

          <b-form-group v-if="local_value.is_sms" id="from_id-group" :label="$t('PAGES.SENDOUT.FROM_ID')" label-for="from_id">
            <b-form-input
              id="from_id"
              v-model="local_value.from_id"
              :placeholder="$t('PAGES.SENDOUT.FROM_ID')"
              :state="validFromId"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <div class="d-flex align-items-center mb-6 mt-4">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  :checked="local_value.is_system"
                  v-model="local_value.is_system"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{$t('TEMPLATE.SYSTEM')}}</span>
            </div>

            <div class="d-flex align-items-center mb-6 mt-4">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  :checked="local_value.is_sms"
                  v-model="local_value.is_sms"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{$t('TEMPLATE.IS_SMS')}}</span>
            </div>


            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  :checked="local_value.is_email"
                  v-model="local_value.is_email"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{$t('TEMPLATE.IS_EMAIL')}}</span>
            </div>
          </b-form-group>


          <b-form-group :label="$t('TEMPLATE.SMS_MESSAGE')" v-if="local_value.is_sms">
            <b-form-textarea
              id="textarea"
              v-model="local_value.text"
              :placeholder="$t('TEMPLATE.SMS_MESSAGE')"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group :label="$t('TEMPLATE.NUM_SMS')" v-if="local_value.is_sms">
            <b-form-input
              id="num_sms"
              v-model="local_value.num_sms"
              :disabled="true"
              :placeholder="$t('TEMPLATE.NUM_SMS')"
            ></b-form-input>
          </b-form-group>


          <div v-if="local_value.is_email">
            <v-tabs
              background-color="white"
              color="accent-4"
              left
            >
              <v-tab>{{ $t('TEMPLATE.DESIGNER') }}</v-tab>
              <v-tab @click="cleartextTabClicked">{{ $t('TEMPLATE.TEXT') }}</v-tab>

              <v-tab-item>
                
                <b-button variant="outline-success" class="btn btn-sm mb-4 mt-4" @click.prevent="exportPlaintextClicked">{{ $t('TEMPLATE.UPDATE_CLEARTEXT') }}</b-button>
                <b-button v-if="isTHS" variant="outline-success" class="btn btn-sm mb-4 mt-4" @click.prevent="export_pdf_clicked">{{ $t('TEMPLATE.EXPORT_PDF') }}</b-button>
                <EmailTemplateEditor
                  ref="emailTemplateEditor"
                  :design="local_value.edit_html"
                />
              </v-tab-item>
              <v-tab-item>
                <b-form-group class="mt-4">
                  <tiptap-vuetify
                    data-app
                    v-model="local_value.text"
                    :extensions="extensions"
                  />
                  
                  <b-form-text id="input-live-help"
                    >{{$t('TEMPLATE.NOTICE')}}</b-form-text
                  >
                </b-form-group>
              </v-tab-item>
            </v-tabs>
          </div>

          <div class="mt-4">
            
            <b-button v-if="sendout_id !== null" variant="success" class="ml-2" @click="backToSendout"
              >{{$t('COMMON.BACK')}}</b-button
            >

            <b-row v-if="unlocks.template_sync && local_value.template_id" >
              
              <b-col>
                <b-button variant="success" class="ml-2" @click="on_create_to_instance_clicked"
                  >{{$t('INSTANCES.CREATE_TO_INSTANCE')}}</b-button
                >
              </b-col>
            </b-row>
            
          </div>

        </b-form>
      </div>

    </b-card>
    <InstancesSelectableTableModal 
      ref="select-instances-modal"
      @confirm="on_confirmed_ths_instances"
    />
  </div>
</template>

<script>

import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import EmailTemplateEditor from '@/view/components/EmailTemplateEditor.vue';
import Instances from '@/view/pages/ml/ths/Instances.vue';

import axios from 'axios';
import { mapGetters } from 'vuex';

import EmailWithValidDomain from '@/view/components/EmailWithValidDomain.vue';
import InstancesSelectableTableModal from '@/view/pages/ml/ths/instances/InstancesSelectableTableModal.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import swal from 'sweetalert2';

export default {
  name: 'TemplateEditor',
  mixins: [ toasts ],
  components: {
    TiptapVuetify,
    EmailTemplateEditor,
    EmailWithValidDomain,
    InstancesSelectableTableModal,
    Instances
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['update:template', 'created'],
  computed: {
    sendout_template_type_options() {

      const base = [
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.DONATION_NEW_DONATION_RECEIVED'), value: 'DONATION_NEW_DONATION_RECEIVED' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.DONATION_THANK_YOU_SINGLE'), value: 'DONATION_THANK_YOU_SINGLE' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.DONATION_THANK_YOU_RECURRING'), value: 'DONATION_THANK_YOU_RECURRING' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SIMPLE_FORM_ANSWERED'), value: 'SIMPLE_FORM_ANSWERED' },
      ];

      if (this.isTHS) {
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SAMFUND_MEMBERS_ERROR_REPORT'), value: 'SAMFUND_MEMBERS_ERROR_REPORT' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.GENERAL_ERROR_REPORT'), value: 'GENERAL_ERROR_REPORT' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.DONATION_NEW_CUSTOMER'), value: 'DONATION_NEW_CUSTOMER' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.DONATION_WELCOME_ADMIN'), value: 'DONATION_WELCOME_ADMIN' });
      }

      if (this.global_settings.type === 'kvitto') {
        
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.EMAIL_NOT_MATCHED'), value: 'EMAIL_NOT_MATCHED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_RECEIVED_AND_FORWARDED'), value: 'KVITTO_RECEIVED_AND_FORWARDED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_ERROR'), value: 'KVITTO_ERROR' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_NO_RULE_MATCHED'), value: 'KVITTO_NO_RULE_MATCHED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_RECEIVED'), value: 'KVITTO_RECEIVED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_MATCHED'), value: 'KVITTO_MATCHED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.KVITTO_NOT_MATCHED'), value: 'KVITTO_NOT_MATCHED' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.ACCOUNT_TRANSACTION_NEED_MATCH'), value: 'ACCOUNT_TRANSACTION_NEED_MATCH' });
      }

      if (this.global_settings.type === 'support') {

        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_INFO_MAIL'), value: 'SALES_INFO_MAIL' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_INVITE_MEETING'), value: 'SALES_INVITE_MEETING' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_FOLLOW_UP'), value: 'SALES_FOLLOW_UP' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_SIGN_CONTRACT'), value: 'SALES_SIGN_CONTRACT' });
        base.push({ text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_WELCOME_AFTER_SIGN'), value: 'SALES_WELCOME_AFTER_SIGN' });
      }

      return base;
    },

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS', 'unlocks', 'customer', 'global_settings']),

    validFromId() {
      return this.validate_from_id();
    },
    fullscreen_visible() {
      const display = this.fullscreen ? 'block' : 'none';
      return `display:${display}`;
    },
    templateDesign() {
      return this.local_value.edit_html;
    },
    creating() {
      return isNaN(parseInt(this.local_value.template_id+''));
    },
    valid_name_state() {
      if (!this.local_value.name) { return false; }
      if (this.local_value.name.length > 0) { return true; }
      return false;
    }
  },
  mounted() {
    if (this.value) {
      this.local_value = { ...this.value };
    }
  },
  data() {
    return {
      sendout_template_type: null,
      instance_id: null,
      instance_options: [],
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      local_value: {},
      isValid: true,

      replyToValid: false,
      fromEmailValid: false,
      stickprov: false,
      sms: false,
      email: false,
      moving: false,
      templates: [],
      fullscreen: false,
      sendout_id: null
    };
  },

  watch: {
    local_value: {
      deep: true,
      handler(new_value, old_value) {
        this.$emit('change', new_value);
        if (new_value.template_id !== old_value.template_id) {
          this.update_editor();
        }
      }
    },

    'local_value.is_sms'(newValue) {
      if (this.local_value.is_sms) {
        this.local_value.from_name = '';
      }
    },

    'local_value.is_email'(newValue) {
      this.local_value.is_sms = !newValue;
      if (this.local_value.is_email) { this.isValid = true; }
    },

    'local_value.text': {
      deep: true,
      handler(new_value, old_value) {
        this.local_value.num_sms = Math.max(1, Math.ceil((new_value+'').length / 140));
      }
    },

    'local_value.from_id'() {
     this.validate_from_id();
   },

    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadTemplates();
      }
    }
  },
  methods: {

    async on_create_to_instance_clicked() {

      this.$refs['select-instances-modal'].show();
    },

    validate_from_id() {
      if (!this.local_value.is_sms) {
        return false;
      }

      if (this.local_value.from_id === null || this.local_value.from_id === undefined || this.local_value.from_id.length === 0) {
        this.isValid = false;
        return false;
      }

      if (this.local_value.from_id.length > 11) {
        this.isValid = false;
        return false;
      }

      const re = /[^a-z\d]/i;

      const test = !re.test(String(this.local_value.from_id).toLowerCase());
      this.isValid = test;
      return test;
    },
    update_editor() {
      if (this.$refs['emailTemplateEditor']) {
        this.$nextTick(() => {

          if (this.$refs['emailTemplateEditor']) {
            this.$refs['emailTemplateEditor'].force_reload();
          }

        });
      }
    },
    async cleartextTabClicked() {
      if (this.$refs['emailTemplateEditor']) {
        const design_data = await this.$refs['emailTemplateEditor'].exportHTML();

        this.local_value.edit_html = JSON.stringify(JSON.stringify(design_data.edit_html)); // double stringify because of problems
        this.local_value.template = design_data.html;
      }
    },

    async on_confirmed_ths_instances(instances) {

      await this.saveTemplate();

      const ids = instances.map(item => (item.id));

      const loader = this.$loading.show();

      const res = await axios.post(`/template/to_instances`, { template_id: this.local_value.template_id, instance_ids: ids });

      if (res.status === 201) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.SYNCED'))
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_SYNC'))
      }

      loader && loader.hide();
    },

    replyToUpdated(email) {
      this.local_value.reply_to = email;
    },

    fromEmailUpdated(email) {
      this.local_value.from_email = email;
    },

    copyTemplateToCompany(template_id, company_id) {
      const loader = this.$loading.show();

      axios
        .post(`/template/copy`, { template_id: template_id, company_id: company_id })
        .then(res => {
          loader && loader.hide();
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_COPY'));
        });
    },

    copyTemplate(template, movable = false) {
      const loader = this.$loading.show();

      if (!movable || template.company_id == this.currentCompanyId) {
        axios
          .post(`/template/copy`, { template_id: template.template_id })
          .then(res => {
            loader && loader.hide();

            this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.COPIED'));
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_COPY'));
          });
      } else {
        axios
          .get(`/template/${template.template_id}`)
          .then(res => {
            loader && loader.hide();
            this.local_value = res.data;
            this.moving = true;

            this.local_value.edit_html = this.local_value.edit_html ? JSON.parse(this.local_value.edit_html) : null;
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
          });
      }
    },
    deleteTemplateClicked(template_id) {
      this.toDelete = template_id;
      this.$refs['deleteTemplateConfirm'].show();
    },
    onDeleteTemplateConfirm() {
      this.deleteTemplate(this.toDelete);
      this.toDelete = null;
    },

    deleteTemplate(template_id) {
      const loader = this.$loading.show();

      axios
        .delete(`/template/${template_id}`)
        .then(res => {
          loader && loader.hide();

          this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.DELETED'));
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_DELETE'));
        });
    },
    changeSystem(template) {
      const loader = this.$loading.show();

      axios
        .put(`/template/${template.template_id}`, template)
        .then(res => {
          loader && loader.hide();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.UPDATED'));
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_UPDATE'));
        });
    },

    async saveTemplate() {
      const loader = this.$loading.show();
      this.local_value.company_id = this.currentCompanyId;

      if (this.$refs['emailTemplateEditor']) {
        this.local_value.text = await this.$refs['emailTemplateEditor'].exportPlaintext();
      }
      
      this.local_value.num_sms = this.local_value.num_sms ? parseInt(this.local_value.num_sms+'') : 1;
      this.local_value.type = 'user';

      if (!this.local_value.from_name) {
        this.local_value.from_name = '';
      }

      // if user forgot to update the plaintext, do it for them
      if (!this.local_value.text || this.local_value.text.length < 2) {
        if (this.$refs['emailTemplateEditor']) {
          this.local_value.text = await this.$refs['emailTemplateEditor'].exportPlaintext();
        }
      }

      // if it failed to update the plaintext, set it as subject
      if (!this.local_value.text || this.local_value.text.length < 2) {
        this.local_value.text = this.local_value.subject;
      }

      if (this.$refs['emailTemplateEditor']) {
        const design_data = await this.$refs['emailTemplateEditor'].exportHTML();

        this.local_value.edit_html = JSON.stringify(JSON.stringify(design_data.edit_html)); // double stringify because of problems
        this.local_value.template = design_data.html;
      }

      if (this.creating) {

        if (this.sms) {
          this.local_value.edit_html = '';
        }

        axios
          .post('/template', this.local_value)
          .then(res => {
            loader && loader.hide();
            if (res.status === 201) {
              this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.CREATED'));
              this.local_value.template_id = res.data.template_id;
              this.$emit('created', this.local_value);
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_CREATE'));
            }
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_CREATE'));
          });
      } else if (!this.creating) {

        axios
          .put(`/template/${this.local_value.template_id}`, this.local_value)
          .then(res => {
            loader && loader.hide();
            if (res.status === 204) {
              this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.UPDATED'));
            }
            else if (res.status === 409) {

              swal.fire({
                title: this.$t('COMMON.ERROR'),
                icon: 'error',
                html: this.$t('TEMPLATE.INVALID_EMAILS'),
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
              });

            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_UPDATE'));
            }
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_UPDATE'));
          });
      }

      if (this.isTHS && this.sendout_template_type) {
        axios.post(`/template/sendout`, { customer_id: this.customer.id, type: this.sendout_template_type, template_id: this.local_value.template_id });
      }

    },
    async export_pdf_clicked() {
      console.log(await this.$refs['emailTemplateEditor'].export_pdf());
    },
    async exportPlaintextClicked() {
      // will emit 'plaintext'
      this.local_value.text = await this.$refs['emailTemplateEditor'].exportPlaintext();
    },

    backToSendout() {
      this.$router.push(`/ml-sendout/${this.sendout_id}`);
    },
    setTemplateProps() {
      const sendout_id = parseInt(this.$route.params.sendout_id);
      const id = this.$route.params.template_id;
      const editing = Boolean(parseInt(this.$route.params.is_edit));

      if (!isNaN(sendout_id)) {
        this.local_value.template_id = editing ? id : '';
        this.sendout_id = sendout_id;
      }
    },
    moveToCompanies() {
      const company_ids = this.$refs['companies_selected'].getCompanies();

      for (const cid of company_ids) {
        this.copyTemplateToCompany(this.local_value.template_id, cid);
      }

      this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.COPIED'));
    }
  }
};
</script>

<style lang="scss" scoped>


.modal-xl {
  max-width: 100% !important;
  padding-right: 17px;
}

:deep .dark-modal {
  color: white;
  background-color: #293039;
  border-bottom: 1px solid #444c56;
}

:deep .dark-modal-body {
  color: white;
  background-color: #293039;
}

</style>
